import * as Sentry from "@sentry/astro";

Sentry.init({
  dsn: "https://1a5d6dcb68a2228c7b41983e6ce4d81c@o124059.ingest.us.sentry.io/4506909851320320",
  debug: false,
  environment: "production",
  release: "20241210.4",
  tracesSampleRate: 0,
  sampleRate: 0.05,
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1,
});